export default class Modale {
    constructor(element) {
        this.element = element;
        this.modaleImage = document.getElementById('modaleImage');
        this.closeButton = this.element.querySelector('.close-modale');

        this.init();
    }

    init() {
        // Ajoute un événement pour ouvrir la modale lorsque l'image est cliquée
        document.querySelectorAll('.open-modale').forEach(img => {
            img.addEventListener('click', (event) => this.openModale(event));
        });

        // Ajoute un événement pour fermer la modale
        this.closeButton.addEventListener('click', () => this.closeModale());

        // Ferme la modale lorsque l'on clique en dehors de l'image
        this.element.addEventListener('click', (event) => {
            if (event.target === this.element) {
                this.closeModale();
            }
        });
    }

    openModale(event) {
        const imageSrc = event.target.getAttribute('data-image');
        this.modaleImage.src = imageSrc;
        this.element.style.display = 'block';
    }

    closeModale() {
        this.element.style.display = 'none';
    }
}

// Initialisation de la modale
document.addEventListener('DOMContentLoaded', () => {
    const modale = new Modale(document.getElementById('imageModale'));
});
