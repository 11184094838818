export default class Dropdown {
    constructor(element) {
        this.element = element;
        this.cards = this.element.querySelectorAll('.card');
        this.init();
    }

    init() {
        this.cards.forEach((card) => {
            card.addEventListener("mouseenter", () => this.mouseenter(card));
            card.querySelector('.dropdown').addEventListener("mouseenter", () => this.mouseenter(card));
        });
    }

    mouseenter(currentCard) {
        // Activer la carte survolée
        currentCard.classList.add('active');
    }

    mouseleave(currentCard) {
        // Désactiver la carte seulement si la souris n'est pas sur la carte ou le dropdown
        if (!currentCard.matches(':hover')) {
            currentCard.classList.remove('active');
        }
    }
}

// Initialisation
document.addEventListener('DOMContentLoaded', () => {
    const competencesSection = document.querySelector('.competences');
    if (competencesSection) {
        new Dropdown(competencesSection);
    }
});
